<template>
<!-- 设置 -->
    <div class="content">
        <div v-for="item in attentionlistdata" :key="item.name" :class="item.class" class="attentionlist">
            <div class="attentionlistBg">
                <div class="leftAttention">
                    <div>{{item.text}}</div>
                </div>
                <div class="rightAttention">
                    <img src="../../assets/images/rightoption.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'personalCenter-settings',
    data() {
        return{
            value: false,
            attentionlistdata:[
                {
                    text: '提醒设置'
                },
                {
                    text: '关于我们',
                    class: 'inval'
                },
                {
                    text: '用户协议',
                    class: 'inval'
                },
                {
                    text: '隐私协议'
                },
                {
                    text: '版本号'
                },
                {
                    text: '清除缓存'
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    .content{
        width: calc(100% - 60px);
        display: inline-block;
        margin-left: 30px;
        margin-top: 20px;
        overflow: auto;
        .inval{
            margin-top: 20px;
        }
        .attentionlist{
            height: 56px;
            background: white;
            .attentionlistBg{
                box-shadow: 0px 1px 0px 0px #EEEEEE;
                display: inline-block;
                height: 100%;
                width: calc(100% - 20px);
                margin-left: 20px;
                .rightAttention{
                    height: 100%;
                    margin-right: 15px;
                    float: right;
                    img{
                        display: inline-block;
                        margin-top: 19px;
                        width: 10px;
                        height: 18px;
                        color: #CCCCCC;
                    }
                }
                .leftAttention{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    padding-left: 15px;
                    float: left;
                    line-height: 56px;
                }
            }
        }
    }
</style>